import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import Layout from "../../components/layout";
import Seo from "../../components/seo";

const PrivacyNotice = () => {
    return (
        <Layout lang={'en'} langURL={'/en/privacy-notice/'}>
            <Seo title={'Privacy Notice'} description={'The complete Privacy Notice for the Teacher\'s hub'} lang={'en-GB'} />
            <main id={'content'} className={'content py-5'} role={'main'}>
                <Container bsPrefix={'wrap'} fluid={false}>
                    <Row>
                        <Col xs={12} className={'pb-5'}>
                            <h1 className={'content--h1'}>Hysbysiad Preifatrwydd Cymdeithas Adeiladu Principality</h1>
                            <h2>Ein manylion cyswllt</h2>
                            <p>Enw: Cymdeithas Adeiladu Principality</p>
                            <p>Rhif Ffôn: 0330 333 4000</p>
                            <p>E-bost: <a href={'mailto:sharedmailbox.digitalteam@principality.co.uk'} className={'content--home--text--link'}>SharedMailbox.DigitalTeam@principality.co.uk</a></p>
                            <h2>Y math o wybodaeth bersonol yr ydym yn ei chasglu</h2>
                            <p>Ar hyn o bryd rydym yn casglu ac yn prosesu'r wybodaeth ganlynol:</p>
                            <ul>
                                <li>Dynodwyr personol, cysylltiadau a nodweddion (er enghraifft, ystod oedran, enw ysgol, tref a chyfeiriad e-bost)</li>
                            </ul>
                            <h2>Sut y cawn yr wybodaeth bersonol a pham ei bod gennym ni</h2>
                            <p>Rhoddir y rhan fwyaf o'r wybodaeth bersonol yr ydym yn ei phrosesu i ni'n uniongyrchol gennych chi am un o'r rhesymau canlynol:</p>
                            <ul>
                                <li>I roi'r wybodaeth ddiweddaraf i chi am hyb Athrawon Dylan, gall hyn gynnwys unrhyw newidiadau neu gynnwys ychwanegol a allai fod o ddiddordeb i chi</li>
                            </ul>
                            <p>Rydym yn defnyddio'r wybodaeth yr ydych wedi'i rhoi i ni er mwyn rhoi gwybod i chi, yn ôl y gofyn, am unrhyw ddiweddariadau i wefan Sgwad Athrawon Dylan a’r hyn y mae hi yn ei gynnig.</p>
                            <p>O dan y Rheoliad Diogelu Data Cyffredinol (GDPR),y seiliau cyfreithlon yr ydym yn dibynnu arnynt ar gyfer prosesu'r wybodaeth hon yw:</p>
                            <p><strong>(a) Eich caniatâd. Cewch dynnu eich caniatâd yn ôl ar unrhyw adeg. Gallwch wneud hyn drwy ffonio 0330 333 4000</strong></p>
                            <p><strong>(b) Mae gennym fuddiant dilys.</strong></p>
                            <h2>Sut rydym yn storio eich gwybodaeth bersonol</h2>
                            <p>Mae eich gwybodaeth yn cael ei storio'n ddiogel yn ein system e-bost gyda mynediad cyfyngedig.</p>
                            <p>Byddwn yn cadw eich cyfeiriad e-bost cyhyd ag y bo'n berthnasol neu eich bod chi'n dad-danysgrifio. Pan fyddwch yn dad-danysgrifio byddwn yn tynnu eich manylion o’r rhestrau postio wedi hynny ac yn gwaredu eich gwybodaeth drwy ddileu eich manylion o'r system e-bost o fewn 12 mis.</p>
                            <h2>Eich hawliau diogelu data</h2>
                            <p>O dan gyfraith diogelu data, mae gennych hawliau gan gynnwys:</p>
                            <p><strong>Eich hawl mynediad</strong> - Mae gennych yr hawl i ofyn i ni am gopïau o'ch gwybodaeth bersonol.</p>
                            <p><strong>Eich hawl i gywiro</strong> - Mae gennych yr hawl i ofyn i ni gywiro gwybodaeth bersonol sy'n anghywir yn eich barn chi. Mae gennych hefyd yr hawl i ofyn i ni gwblhau gwybodaeth sy'n anghyflawn yn eich barn chi.</p>
                            <p><strong>Eich hawl i ddileu</strong> - Mae gennych yr hawl i ofyn i ni ddileu eich gwybodaeth bersonol mewn rhai amgylchiadau.</p>
                            <p><strong>Eich hawl i gyfyngu ar brosesu</strong> - Mae gennych yr hawl i ofyn i ni gyfyngu ar brosesu eich gwybodaeth bersonol mewn rhai amgylchiadau.</p>
                            <p><strong>Eich hawl i wrthwynebu prosesu</strong> - Mae gennych yr hawl i wrthwynebu prosesu eich gwybodaeth bersonol mewn rhai amgylchiadau.</p>
                            <p>Nid yw'n ofynnol i chi dalu unrhyw dâl am arfer eich hawliau. Os byddwch yn gwneud cais, mae gennym ni un mis i ymateb i chi.</p>
                            <p>Cysylltwch â ni <a href={'mailto:dpo@principality.co.uk'} className={'content--home--text--link'}>DPO@principality.co.uk</a> os hoffech wneud cais.</p>
                            <h2>Sut i gwyno</h2>
                            <p>Os oes gennych unrhyw bryderon am ein defnydd o'ch gwybodaeth bersonol, cewch wneud cwyn i ni ar 0330 333 4000 neu drwy ysgrifennu atom: Cymdeithas Adeiladu'r Principality, Blwch Post 89, Heol y Frenhines, Caerdydd CF10 1UA.</p>
                            <p>Cewch hefyd gwyno i Swyddfa'r Comisiynydd Gwybodaeth os ydych yn anhapus â'r ffordd yr ydym wedi defnyddio eich data.</p>
                            <p>Cyfeiriad Swyddfa'r Comisiynydd Gwybodaeth:</p>
                            <p>
                                Information Commissioner’s Office<br />
                                Wycliffe House<br />
                                Water Lane<br />
                                Wilmslow<br />
                                Cheshire<br />
                                SK9 5AF
                            </p>
                            <p>Rhif llinell gymorth: 0303 123 1113</p>
                            <p>Gwefan Swyddfa'r Comisiynydd Gwybodaeth: <a href={'https://www.ico.org.uk'} rel={'noreferrer'} className={'content--home--text--link'} target={'_blank'}>https://www.ico.org.uk</a></p>
                        </Col>
                    </Row>
                </Container>
            </main>
        </Layout>
    )
};

export default PrivacyNotice;